import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    token: String,
  };

  connect() {
    this.initializeActivepieces();
  }

  initializeActivepieces() {
    this.configureActivepieces();
  }

  configureActivepieces() {
    activepieces
      .configure({
        prefix: "/workflows",
        instanceUrl: this.urlValue,
        jwtToken: this.tokenValue,
        embedding: {
          containerId: "container",
          builder: {
            disableNavigation: false,
            hideLogo: true,
            hideFlowName: false,
          },
          dashboard: {
            hideSidebar: true,
          },
          hideFolders: true,
          navigation: {
            handler: ({ route }) => {
              if (!window.location.href.endsWith(route)) {
                window.history.pushState({}, "", window.location.origin + route);
              }
            },
          },
        },
      })
      .then(this.handleSuccess.bind(this));
  }

  navigateToExtractedRoute() {
    const route = activepieces.extractActivepiecesRouteFromUrl({ vendorUrl: window.location.href });
    activepieces.navigate({ route });
  }

  handleSuccess(result) {
    if (result.status === "success") {
      this.navigateToExtractedRoute();
    }

    this.handlePopstate();
  }

  handlePopstate() {
    this.popstateHandler = () => {
      this.navigateToExtractedRoute();
    };

    window.addEventListener("popstate", this.popstateHandler);
  }

  disconnect() {
    // remove popstate event listener
    if (this.popstateHandler) {
      window.removeEventListener("popstate", this.popstateHandler);
    }
  }
}
