// All Stimulus controllers inside app/frontend/controllers will be registered automatically

import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Generic controllers
const genericControllers = import.meta.glob("../controllers/**/*_controller.js", { eager: true });

for (let path in genericControllers) {
  let module = genericControllers[path];
  let name = path
    .match(/\.\/(.+)_controller\.js$/)[1]
    .replaceAll("_", "-")
    .replaceAll("/", "--");

  application.register(name, module.default);
}

// Controllers from components
const controllers = import.meta.glob("../components/**/controller.js", { eager: true });

for (let path in controllers) {
  let module = controllers[path];
  let name = path
    .match(/components\/(.+)\/controller\.js$/)[1]
    .replaceAll("_", "-")
    .replaceAll("/", "--");

  application.register(name, module.default);
}
